export function fvSlider() {
  const fvSlide = document.querySelector('.un-fv_slider');
  if (fvSlide) {
    const mySwiper = new Swiper(fvSlide, {
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      watchSlidesProgress: true,
      slidesPerView: 1, // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
      spaceBetween: 0, // スライド間の余白（px）
      centeredSlides: true,
      // followFinger: false,
      loop: true,
      loopAdditionalSlides: 1,
      speed: 1500,
      autoplay: {
        delay: 4500,
        // disableOnInteraction: false,
        // waitForTransition: false,
      },
    });
  }
}
