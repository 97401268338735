export function dropDown() {
  const dropDownTrigger = document.querySelector('.js-bropDownTrigger');
  const dropDownMenu = <HTMLInputElement>(
    document.querySelector('.js-dropDownMenu')
  );
  const dropDownParent = <HTMLInputElement>(
    document.querySelector('.js-dropDownParent')
  );
  if (dropDownTrigger) {
    dropDownTrigger.addEventListener('click', function () {
      if (dropDownMenu) {
        dropDownTrigger.classList.toggle('-open');
        if (dropDownTrigger.classList.contains('-open')) {
          const menuHeight: number = dropDownMenu.offsetHeight;
          dropDownParent.style.height = menuHeight + 'px';
        } else {
          dropDownParent.style.height = 0 + 'px';
        }
      }
    });
  }
}
