export function hamBtn() {
  const hamBtn = <HTMLInputElement>document.querySelector('.js-hamBtn');
  const header = document.querySelector('.js-header');
  const headerLink = document.querySelectorAll('.js-header a');
  if (hamBtn) {
    hamBtn.addEventListener('click', function () {
      this.classList.toggle('-active');
      if (header) {
        header.classList.toggle('-show');
      }
    });
  }
  if (headerLink) {
    for (let i = 0; i < headerLink.length; i++) {
      headerLink[i].addEventListener('click', function () {
        if (hamBtn.classList.contains('-active')) {
          hamBtn.classList.remove('-active');
        }
        if (header && header.classList.contains('-show')) {
          header.classList.remove('-show');
        }
      });
    }
  }
}
