export function changeTab() {
  const tab = document.querySelectorAll('.js-tab');
  const tabContent = document.querySelectorAll('.js-content');
  if (tab) {
    for (let i = 0; i < tab.length; i++) {
      tab[i].addEventListener('click', function () {
        const activeTab = <HTMLInputElement>(
          document.querySelector('.js-tab.-active')
        );
        const activeContent = <HTMLInputElement>(
          document.querySelector('.js-content.-active')
        );
        activeTab.classList.remove('-active');
        activeContent.classList.remove('-active');
        tab[i].classList.add('-active');
        if (tabContent) {
          tabContent[i].classList.add('-active');
        }
      });
    }
  }
}
