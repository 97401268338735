export function pageLink() {
  const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
  if (smoothScrollTrigger) {
    for (let i = 0; i < smoothScrollTrigger.length; i++) {
      smoothScrollTrigger[i].addEventListener('click', (e) => {
        e.preventDefault();
        let href = smoothScrollTrigger[i].getAttribute('href');
        if (href) {
          let targetElement = document.getElementById(href.replace('#', ''));
          if (targetElement) {
            const rect = targetElement.getBoundingClientRect().top;
            const offset = window.pageYOffset;
            const target = rect + offset;
            window.scrollTo({
              top: target,
              behavior: 'smooth',
            });
          }
        }
      });
    }
  }
}
