export function cvHidden() {
  const footer = <HTMLInputElement>document.querySelector('.js-footer');
  const cv = <HTMLInputElement>document.querySelector('.js-online');
  const options = {
    threshold: 0,
  };
  // csボタン非表示
  const observer = new IntersectionObserver(cvHiddenAction, options);
  observer.observe(footer);
  function cvHiddenAction(entries: any) {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        cv.classList.add('is-hidden');
      } else {
        cv.classList.remove('is-hidden');
      }
    });
  }
  // cvボタン表示
  const observerShow = new IntersectionObserver(cvShowAction, options);
  observerShow.observe(cv);
  function cvShowAction(entries: any) {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        cv.classList.add('is-show');
      }
    });
  }
}
