import { mediaQuery } from './matchMedia';
export function navActive(target: any) {
  if (mediaQuery('1025').matches) {
    const navLinkParent = document.querySelector('.js-navLinkParent');

    for (let i = 0; i < target.length; i++) {
      target[i].addEventListener(
        'mouseover',
        function () {
          if (navLinkParent) {
            navLinkParent.classList.add('-gray');
            target[i].classList.add('-active');
          }
        },
        false
      );
      target[i].addEventListener(
        'mouseleave',
        function () {
          if (navLinkParent) {
            navLinkParent.classList.remove('-gray');
            target[i].classList.remove('-active');
          }
        },
        false
      );
    }
  }
}
