import { dropDown } from './src/dropDown';
dropDown();

import { navActive } from './src/navActive';
const navLink = document.querySelectorAll('.js-navLink');
const megaMenu = document.querySelectorAll('.js-megaMenu');
navActive(navLink);
navActive(megaMenu);

import { hamBtn } from './src/hamBtn';
hamBtn();

import { fvSlider } from './src/swiper';
fvSlider();

import { cvHidden } from './src/cvHidden';
cvHidden();

import { viewPort } from './src/viewPort';
viewPort();

import { pageLink } from './src/pageLink';
pageLink();

import { changeTab } from './src/tabChange';
changeTab();
